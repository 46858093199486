@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';

@keyframes append {
  30% { transform: scale(1.5); }
  40%, 60% { transform: rotate(-20deg) scale(1.5); }
  50% { transform: rotate(20deg) scale(1.5); }
  70% { transform: rotate(0deg) scale(1.5); }
  100% { transform: scale(1); }
}

.btn {
  &::after {
    animation: append 1s 1;
  }

  &.none::after {
    animation: none;
  }

  &:disabled {
    opacity: 0.2;
  }
}

.downloads {
  .masked-button('~@mdi/svg/svg/download-circle-outline.svg', 60%);
  .btn();
}

.shares {
  .masked-button('~@mdi/svg/svg/share-variant-outline.svg', 60%);
  .btn();
}

.root {
  background-color: @header-background;
  font-size: 0.9em;
  max-height: 60%;
  max-width: 100%;
  min-width: 30em;
  overflow-y: auto;
  padding: 1em 2em 2em;
  width: 25%;
  z-index: 1000;
}


.list {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
    padding: 0.5em 0;

    div {
      align-items: center;
      display: flex;
    }
  }
}

.name {
  flex: 1;
  overflow: hidden;
  padding: 0 0.5em;
  text-overflow: ellipsis;
  white-space: pre;
}

.dismiss,
.download,
.processing {
  height: 2em;
  width: 2em;
}

.icon {
  height: 1em;
  width: 1em;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end
}

.collection {
  .masked-button('~@mdi/svg/svg/playlist-play.svg');
}

.moment {
  .masked-button('~@mdi/svg/svg/play.svg');
}

.clear {
  margin-top: 0.5em;
  width: 100%;
}

.dismiss {
  .masked-button('~@mdi/svg/svg/close.svg', 60%);
}

.processing {
  .masked-button('~images/spinner.svg', 60%);
}

.download {
  .masked-button('~@mdi/svg/svg/download.svg', 60%);
}

.btnActive {
  .masked-button('~images/spinner.svg', 100%);

  background-color: fade(@header-background, 90%);
  border-radius: 500em;

  &::after {
    background-color: @body-color;
  }
}

.btnError {
  .masked-button('~@mdi/svg/svg/circle.svg', 60%);

  &::after {
    background-color: @danger;
  }
}

.btnActive,
.btnError {
  bottom: 20%;
  height: 30%;
  position: absolute;
  right: 20%;
  width: 30%;
  z-index: 10000;
}

.img {
  border: solid 1px @body-color;
  margin: 0.5em 0;
  width: 100%;
}

.berrors {
  font-size: 0.8em;
  padding-left: 1.5em;
}

.errors {
  .berrors();

  color: red;
  flex: 1;
  padding: 0 3.5em;

  li {
    list-style-type: disc;
    padding: 0;
  }
}

