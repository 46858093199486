@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';
@import '../../mixins.less';

@active-color: fade(@body-color, 10%);

@tile-animation-duration: 100ms;

.select {
  .button();

  display: none;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  text-indent: -100vw;
  top: 0;
  width: 100%;
}

.blocked {
  .blocked();

  padding: 0 10%;
}

.checkbox {
  .button();
  .fadeOut();

  background-color: @tile-background-color;
  border-radius: 50%;
  left: 0;
  position: absolute;
  top: 0;

  * {
    touch-action: none;
    pointer-events: none;
  }
}

.selecting .checkbox {
  background-color: transparent;
}

.disabled,
.selecting .item.disallowed {
  .disabled();
}

.hidden {
  display: none;
}

.rtile {
  .tile-bg();

  background-color: transparent;
  border: 0;
  color: @body-color;
  display: block;
  margin: 0;
  padding: 0 0 (100% * @video-proportions);
  position: relative;
  width: 100%;

  &:hover {
    color: @body-color;
  }
}

.thumbnail {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  object-fit: cover;
  position: absolute !important;
  top: 0;
  width: 100%;

  img {
    object-fit: contain;
    pointer-events: none;
    touch-action: none;
  }
}

.info {
  color: @body-color;
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  line-height: 1.8em;
  opacity: .75;
}

.name {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-inline-box;
  font-size: 1em;
  font-weight: 500;
  height: 2.8em;
  line-height: 1.4em;
  overflow: hidden;
}

.extra {
  font-size: 0.9em;
  padding: 0.5em 1em;
  position: relative;

  > * {
    padding-top: 0.5em;

    &:first-child {
      padding-top: 0;
    }
  }
}

.top {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: @tile-top-size;
  justify-content: flex-end;
  position: relative;
}

.item {
  background-color: @tile-background-color;
  border: solid 1px @active-color;
  border-radius: @tile-border-radius;
  overflow: hidden;
  scroll-snap-align: start;
  scroll-margin-left: (@tile-margin-x / 2);

  &:not(.noEffect) {
    transition: background-color @tile-animation,
                border-color @tile-animation,
                box-shadow @tile-animation,
                font-size @tile-animation,
                height @tile-animation,
                min-width @tile-animation,
                transform @tile-animation,
                width @tile-animation;

    &:hover {
      border-color: @weplayed-blue-light;
      box-shadow: 0 0 10px fade(@weplayed-blue-light, 70%);
    }

    &:focus {
      border-color: @weplayed-blue-light;
      box-shadow: 0 0 10px fade(@weplayed-blue-light, 70%);
      outline: dotted 1px @body-color;
    }

    &[tabindex] {
      cursor: pointer;
    }
  }

  &.highlighted:not(.noEffect) {
    background-color: fade(@weplayed-accent, 20%);
  }

  &[draggable="true"] {
    cursor: move;
  }

  &.drop {
    background-color: fade(@body-color, 10%);
    display: block;

    span {
      align-items: center;
      display: flex;
      font-size: 0.8em;
      justify-content: center;
      height: 100%;
      text-transform: uppercase;
      width: 100%;
    }

    &.nodrop {
      background-color: inherit;

      span {
        display: none;
      }
    }
  }
}

.selecting .item,
.item:hover,
.item:focus-within {
  &:not(.disallowed) {
    .checkbox {
      .fadeIn();
    }
  }
}

.control {
  .fadeOut();

  &.visible {
    .fadeIn()
  }
}

.item:hover .control,
.item:focus-within .control {
  .fadeIn();
}

.selecting {
  .item .control {
    .fadeOut();
  }

  .select {
    display: block;
  }
}

.dragover {
  .item:hover {
    border-color: @active-color;
    box-shadow: inherit;
    transform: inherit;
    transition: inherit;
  }
}

.tiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.game {
  .rtile {
    position: relative;
  }

  .teams {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: space-around;
    position: absolute;
    width: 100%;

    .team {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      flex: 1;
      height: 100%;
      justify-content: center;

      img {
        height: 45%;
        object-fit: contain;
        object-position: center;
        width: 45%;
      }

      .teamName {
        font-size: 0.6em;
        width: 80%;
        text-align: center;
        overflow: hidden;
        height: 3em;
      }

      .score {
        font: bold 1.2em/1em @weplayed-special-font;
        margin-top: 10px;
      }
    }
  }

  .date {
    text-align: left;
    width: 100%;
  }

  .live {
    font-size: 0.6em;
    left: 1em;
    pointer-events: none;
    position: absolute;
    top: 1em;
    touch-action: none;
  }

  .thumbnail {
    filter: grayscale(1) sepia(0.2) brightness(0.3);
  }
}

.player {
  .thumbnail {
    height: 60%;
    margin: 10% 20%;
    width: 60%;

    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  .photo {
    filter: brightness(25%);
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.moment {
  .moment-description {
    font-size: 1em;
  }
}

.creator {
  .fadeIn();

  flex: 1;
}

.created {
  bottom: 0.5em;
  position: absolute;
  right: 1em;
}

.score {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 1em;
  margin-top: 0.25em;
  white-space: nowrap;

  .team {
    background-color: @body-color;
    border-radius: 50%;
    height: 1.5em;
    margin: 0 0.5em;
    object-fit: contain;
    width: 1.5em;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.position {
  align-items: center;
  background-color: @body-bg;
  border-radius: 1em;
  color: @body-color;
  display: flex;
  box-shadow: 0 0 0.25em @body-color;
  font-weight: bold;
  height: 2em;
  justify-content: center;
  left: 1em;
  position: absolute;
  top: 1em;
  width: 2em;
}

.base {
  background-color: @yellow;
  border-radius: 0.25em;
  box-shadow: 0 0 0.25em @body-color;
  color: @black;
  font-size: 0.5em;
  font-weight: bold;
  padding: 0 0.25em;
  position: absolute;
  right: 1em;
  text-transform: uppercase;
  top: 1em;
}

.algo {
  .masked-button('~@mdi/svg/svg/lightning-bolt-outline.svg', 70%);

  background-color: @tile-background-color;
  bottom: 0.25em;
  display: block;
  height: 1.5em;
  position: absolute;
  right: 0.25em;
  width: 1.5em;

  &::after {
    background-color: darken(@body-color, 20%);
  }

  &.auto::after {
    background-color: @body-color;
    .button-mask-url('~@mdi/svg/svg/lightning-bolt.svg');
  }
}

[data-size="large"] {
  .tiles {
    .tiles();
  }

  .item {
    .tile();
  }
}

[data-size="compact"] {
  .checkbox {
    height: @tile-top-size * @compact-scale;
    width: @tile-top-size * @compact-scale;
  }

  .tiles {
    .tiles(@compact-scale);
  }

  .item {
    .tile(@compact-scale);
  }

  .name {
    font-size: 0.8em;
  }

  .top {
    height: (@tile-top-size * @compact-scale);
  }

  .control {
    font-size: 0.7em;
  }

  .position {
    font-size: 0.8em;
  }

  .extra {
    padding: 0.25em 0.5em;

    > * {
      padding-top: 0.25em;

      &:first-child {
        padding-top: 0;
      }
    }
  }
}
