@import "./variables.less";
@import "~common/styles/mixins.less";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}

.background-special-gradient {
  background: rgb(51,81,138);
  background: linear-gradient(104deg, @gradient-special-from 0%, @gradient-special-to 100%);
}

.background-gray-gradient {
  background: rgb(255,255,255);
  background: linear-gradient(104deg, @gradient-gray-from 0%, @gradient-gray-to 100%);
}

.no-hover-effect(@color: white) {
  color: @color;

  &:hover,
  &:focus {
    color: @color;
  }
}

.absolute-full(@zIndex: auto) {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: @zIndex;
}

.tile-bg(@size: 360) {
  background-image: url("~images/bg/foot-@{size}.jpg");
  background-size: cover;
  background-position: center center;

  @tsize: @size * 2;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url("~images/bg/foot-@{tsize}.jpg");
  }

  &:global {
    &.MCBASK,
    &.WCBASK {
      background-image: url("~images/bg/bask-@{size}.jpg");

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url("~images/bg/bask-@{tsize}.jpg");
      }
    }

    &.MCFENC,
    &.WCFENC {
      background-image: url("~images/bg/fencing-@{size}.jpg");

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url("~images/bg/fencing-@{tsize}.jpg");
      }
    }

    &.MCSOCC,
    &.WCSOCC {
      background-image: url("~images/bg/socc-@{size}.jpg");

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url("~images/bg/socc-@{tsize}.jpg");
      }
    }

    &.MCLAX,
    &.WCLAX {
      background-image: url("~images/bg/lacr-@{size}.jpg");

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url("~images/bg/lacr-@{tsize}.jpg");
      }
    }

    &.MCVOLL,
    &.WCVOLL {
      background-image: url("~images/bg/voll-@{size}.jpg");

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url("~images/bg/voll-@{tsize}.jpg");
      }
    }

    &.MCTENN,
    &.WCTENN {
      background-image: url("~images/bg/tenn-@{size}.jpg");

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url("~images/bg/tenn-@{tsize}.jpg");
      }
    }

    &.MCHOCK,
    &.WCHOCK {
      background-image: url("~images/bg/hock-@{size}.jpg");

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url("~images/bg/hock-@{tsize}.jpg");
      }
    }

    &.MCBASE,
    &.WCSOFT {
      background-image: url("~images/bg/soft-@{size}.jpg");

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url("~images/bg/soft-@{tsize}.jpg");
      }
    }

    &.MCWPOLO,
    &.WCWPOLO {
      background-image: url("~images/bg/wpolo-@{size}.jpg");

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url("~images/bg/wpolo-@{tsize}.jpg");
      }
    }

    &.WCFHOC {
      background-image: url("~images/bg/fieldhockey-@{size}.jpg");

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url("~images/bg/fieldhockey-@{tsize}.jpg");
      }
    }

    &.MCROW,
    &.WCROW {
      background-image: url("~images/bg/rowing-@{size}.jpg");

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url("~images/bg/rowing-@{tsize}.jpg");
      }
    }

    &.MCTF,
    &.WCTF {
      background-image: url("~images/bg/track-field-@{size}.jpg");

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url("~images/bg/track-field-@{tsize}.jpg");
      }
    }

    &.MCWRSTL,
    &.WCWRSTL {
      background-image: url("~images/bg/wrstl-@{size}.jpg");

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url("~images/bg/wrstl-@{tsize}.jpg");
      }
    }

    &.WCACRO {
      background-image: url("~images/bg/acro-@{size}.jpg");

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url("~images/bg/acro-@{tsize}.jpg");
      }
    }

    &.MCSWM,
    &.WCSWM {
      background-image: url("~images/bg/swim-@{size}.jpg");

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url("~images/bg/swim-@{tsize}.jpg");
      }
    }
  }
}

.tile-image {
  height: @tile-desktop-medium-height;
  position: relative;
  width: @tile-desktop-medium-width;
  border-radius: @tile-border-radius;

  .tile-bg(360);

  .small {
    .tile-bg(250);
  }


  @media (max-width: 500px) {
    height: @tile-mobile-medium-height;
    width: @tile-mobile-medium-width;

    .tile-bg(250);

    &.small {
      .tile-bg(200);
    }
  }
}

.tile-root() {
  width: @tile-desktop-medium-width;
  margin-bottom: @tile-desktop-medium-spacing;
  margin-right: @tile-desktop-medium-spacing;

  &:global(.small) {
    margin-bottom: @tile-desktop-small-spacing;
    margin-right: @tile-desktop-small-spacing;
    width: @tile-desktop-small-width;
  }

  @media (max-width: 500px) {
    margin-bottom: @tile-mobile-medium-spacing;
    margin-right: @tile-mobile-medium-spacing;
    width: @tile-mobile-medium-width;

    &:global(.small) {
      margin-bottom: @tile-mobile-small-spacing;
      margin-right: @tile-mobile-small-spacing;
      width: @tile-mobile-small-width;
    }
  }
}

.tile(@scale: 1) {
  @size: (@tile-size * @scale);

  font-size: 1rem;
  margin: ((@tile-margin-y * @scale) / 2) ((@tile-margin-x * @scale) / 2);
  min-width: @size;
  position: relative;
  width: @size;
}

.tiles-container(@scale: 1) {
  @size: ((@tile-size + @tile-margin-x) * @scale);
  @margin: (@tile-margin-x * @scale);

  font-size: 1rem;
  margin: 0 auto;
  width: @size * 2 - @margin;

  each(range(3, 8, 1), {
    @media (min-width: (15rem + (@size * @index))) {
      width: ((@size * @index) - @margin);
    }
  });
}

.tiles(@scale: 1) {
  margin: -((@tile-margin-y * @scale) / 2) -((@tile-margin-x * @scale) / 2);
}

// mixin for base css class of element which should appear with slight delay
.delayed-disappear() {
  opacity: 0;
  transition: opacity @animation-duration 0s,
              visibility 0s @animation-duration;
  visibility: hidden;
}

// mixin for hover css class of element which should appear with slight delay
// put inside &:hover class description
.delayed-appear() {
  opacity: 1;
  transition: opacity @animation-duration @animation-delay-duration,
              visibility 0s @animation-delay-duration;
  visibility: visible;
}