@import '~cms/styles/mixins.less';

@avatar: 2em;

.root {
  width: 50em;

  h5 span {
    opacity: 0.7;
    font-size: 0.8em;
    margin-left: 1em;
    font-weight: normal;
  }
}

.help {
  font-style: oblique;
  margin: 0 1em 1em;
  display: block;
}

.avatar {
  height: @avatar * 1.5;
  width: @avatar * 1.5;
}

.remove {
  .masked-button('~@mdi/svg/svg/close.svg', 60%);

  border: solid 1px fade(@body-color, 10%);
  border-radius: 0.333em;
  text-indent: 0;
  padding: 0.5em 2em 0.5em 0.5em;

  &::after {
    width: 2em;
    left: auto;
    right: 0;
  }
}

.table {
  margin-top: 2em;
  td {
    vertical-align: middle;
    padding-bottom: 0.25em;
    padding-top: 0.25em;
  }
}

.tdAvatar {
  width: @avatar;
}

.tdLink {
  width: 6em;
}

.tableShare {
  margin: 0 auto 0 0;
  max-width: 20em;

  td {
    vertical-align: middle;
  }
}

.tdShare {
  width: 15em;

  svg {
    margin-right: 1em;
    width: 2em;
    height: 2em;
  }
}

.share {
  .masked-button('~@mdi/svg/svg/share.svg', 60%);

  height: 2em;
  width: 2em;
}

.copy {
  justify-content: space-between;
}

.destinations {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1em;

  .target {
    display: flex;
  }

  .manage {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.list {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0;
  padding: 0;

  button {
    background-color: transparent;
    border: solid 2px transparent;
    margin: 0.5em 0.25em 0.5em 0;
    outline: none;
    padding: 0.5em;

    &.selected {
      border-color: white;
    }

    &[disabled] .icon {
      opacity: 0.25;
    }
  }
}

.icon {
  height: 2em;
  width: 2em;
}

.buttons {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1em;
}

.stats {
  color: gray;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.25em;
}

.error {
  color: red;
  font-size: small;
}
