@import '~common/styles/variables.less';
@import '~common/styles/mixins.less';

.root {
  border-radius: 500em;
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:hover .button {
    opacity: 1;
  }
}

.input {
  bottom: 0;
  cursor: pointer;
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.button {
  .masked-button('~@mdi/svg/svg/camera.svg', 40%);

  background-color: fade(@weplayed-background, 70%);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
