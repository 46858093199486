@import '~common/styles/variables.less';

.root {
  align-items: center;
  background-color: @weplayed-secondary-cta-hover;
  border-radius: 500em;
  border: none;
  color: @body-bg;
  display: flex;
  padding: 0.25em;

  span {
    display: none;
    font-weight: bold;
    margin: 0 0.5em 0 0.25em;
    text-transform: uppercase;
  }

  &.text span {
    display: block;
  }

  #media-breakpoint-down(@layout-toggle, {
    &.text.collapse span {
      display: none;
    }
  });

  svg {
    width: 1.6em;
    height: 1.6em;

    &.processing {
      fill: @weplayed-accent;
    }

    &.liveOnline {
      fill: red;
    }

    .liveOffline {
      fill: grey;
    }
  }
}
