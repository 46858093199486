.button {
  display: inline-flex;
  align-items: center;
  font-size: 0.7em;
  margin: 0 0.5em;
  vertical-align: -0.3em;

  span {
    margin-left: 0.5em;
  }

  svg {
    height: 1.6em;
    width: 1.6em;
  }
}
