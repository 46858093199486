.button-mask-url(@url) {
  -webkit-mask-image: url(@url);
  mask-image: url(@url);
}

.button-mask-position(@position-x: center, @position-y: center) {
  -webkit-mask-position: @position-x @position-y;
  mask-position: @position-x @position-y;
}

.button-mask-size(@size: 100%) {
  -webkit-mask-size: auto @size;
  mask-size: auto @size;
}

.button-mask-repeat(@x-repeat: no-repeat, @y-repeat: no-repeat) {
  -webkit-mask-repeat: @x-repeat @y-repeat;
  mask-repeat: @x-repeat @y-repeat;
}

.button-mask(@url: '', @size: 100%, @position: center) when (@url = '') {
  .button-mask-position(@position);
  .button-mask-repeat();
  .button-mask-size(@size);

  overflow: hidden;
  text-indent: -100em;
}

.button-mask(@url: '', @size: 100%, @position: center) when not (@url = '') {
  .button-mask-position(@position);
  .button-mask-repeat();
  .button-mask-size(@size);
  .button-mask-url(@url);

  overflow: hidden;
  text-indent: -100em;
}

.masked-button(@url: '', @size: 100%, @position: center) when (@url = '') {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-indent: -100em;

  &[disabled] {
    opacity: 0.2;
  }

  &::after {
    .button-mask-position(@position);
    .button-mask-repeat();
    .button-mask-size(@size);

    background-color: @body-color;
    content: '';
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}

.masked-button(@url: '', @size: 100%, @position: center) when not (@url = '') {
  background-color: transparent;
  border: 0;
  color: @body-color;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-indent: -100em;

  &::after {
    .button-mask-position(@position);
    .button-mask-repeat();
    .button-mask-size(@size);
    .button-mask-url(@url);

    background-color: @body-color;
    content: '';
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}

._scrollbar(@size, @color) {
  @half: (@size / 2);

  scrollbar-width: @size;

  &::-webkit-scrollbar {
    width: @size;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: @half;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    scrollbar-color: transparent;
    background-color: transparent;
    border-radius: @half;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: @color;
    scrollbar-color: @color;
  }
}

.scrollbar(@horizontal: false) when (@horizontal = false) {
  @size: 8px;

  ._scrollbar(@size, fade(@body-color, 25%));

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: @size;
  }
}

.scrollbar(@horizontal: false) when (@horizontal = true) {
  @size: 8px;

  ._scrollbar(@size, fade(@body-color, 25%));

  overflow-x: auto;

  &::-webkit-scrollbar {
    height: @size;
  }
}
