.root {
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 1em;
  font-style: oblique;
  justify-content: center;
  margin: 5em;
  text-align: center;
}
