@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';

@toggle: 76%;

.root {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 1.5em;
  margin: 0;
  padding: 0;
  position: relative;
}

.toggle {
  aspect-ratio: 2;
  background-color: @body-color;
  border-radius: 500em;
  height: 100%;
  position: relative;

  span {
    aspect-ratio: 1;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: left linear (@animation-duration / 2),
                background-color linear (@animation-duration / 2);

    &::before {
      background-color: fade(@body-bg, 50%);
      border-radius: 500em;
      content: '';
      height: @toggle;
      left: (100% - @toggle) / 2;
      position: absolute;
      top: (100% - @toggle) / 2;
      width: @toggle;
    }

    &.loading::after {
      .button-mask-position(center);
      .button-mask-size(100%);
      .button-mask-url('~images/spinner.svg');

      background-color: @body-color;
      content: '';
      height: @toggle;
      left: (100% - @toggle) / 2;
      position: absolute;
      top: (100% - @toggle) / 2;
      width: @toggle;
    }
  }
}

.label {
  line-height: 1em;
  margin-left: 1em;
}

.input {
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;

  &:disabled {
    cursor: default;

    & + * {
      filter: grayscale();
      opacity: 0.2;
    }
  }

  &:checked + .toggle {
    span {
      left: auto;
      right: 0;

      &::before {
        background-color: @weplayed-blue-light;
      }
    }
  }
}

