@import '~common/styles/mixins.less';

@background: #00000055;
@color: #ffffff;

.root {
  background-color: @background !important;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  // override videojs
  display: flex !important;
  font-size: 0;
  left: 50%;
  margin: 0;
  min-width: 5em;
  outline: none;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 15%;

  @media (pointer:coarse) {
    min-width: 7em;
  }

  &::before {
    content: '';
    padding-bottom: 100%;
  }

  &::after {
    .button-mask('~@mdi/svg/svg/play-circle.svg');

    background-color: @color;
    content: '';
    height: 90%;
    left: 5%;
    position: absolute;
    top: 5%;
    width: 90%;
  }

  &.playing::after {
    .button-mask-url('~@mdi/svg/svg/pause-circle.svg');
  }
}
