.root {
  overflow-x: hidden;

  &.pad {
    margin-top: 1em;
  }

  &.fit > * {
    width: 100%;
  }
}
