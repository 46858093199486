@import '~cms/styles/variables.less';

:global {
  #app {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;
  }

  #content,
  #content > div[style] {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  select {
    background-color: @weplayed-primary;
    border-radius: 0.25em;
    border: none;
    color: @body-color;
    padding: 0.25em;

    option {
      color: @body-color;
      background-color: @body-bg;
      border: none;
    }
  }
}
