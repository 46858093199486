@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';
@import '../mixins.less';

.tiles {
  position: relative;

  > button {
    background-color: transparent;
    border: 0;
    height: 100%;
    overflow: hidden;
    padding: 0;
    position: absolute;
    text-indent: -500em;
    top: 0;
    width: 2em;

    &:disabled::after {
      background-color: fade(@body-color, 20%);
    }

    &::after {
      -webkit-mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-size: 100%;
      background-color: @body-color;
      content: '';
      height: 100%;
      left: 0;
      mask-position: center center;
      mask-repeat: no-repeat;
      mask-size: 100%;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &.prev {
      left: 0;
      transform: translateX(-100%);

      &::after {
        -webkit-mask-image: url(~@mdi/svg/svg/chevron-left.svg);
        mask-image: url(~@mdi/svg/svg/chevron-left.svg);
      }
    }

    &.next {
      right: 0;
      transform: translateX(100%);

      &::after {
        -webkit-mask-image: url(~@mdi/svg/svg/chevron-right.svg);
        mask-image: url(~@mdi/svg/svg/chevron-right.svg);
      }
    }

    .hidden {
      opacity: 0;
    }
  }
}

.disabled {
  .disabled();
}

.single {
  flex-wrap: nowrap !important;
  overflow: hidden !important;
}

.sliderContainer {
  padding-bottom: 1em;
  width: 100% !important;
}

.slider {
  .scrollbar(true);

  overflow-x: auto !important;
  overflow-y: hidden !important;
  scroll-snap-type: x mandatory;
}

@tint-start: 0.1em;
@tint-end: 1em;
@prev: -webkit-linear-gradient(
  left,
  #00000000 @tint-start,
  #000000ff @tint-end,
);

@next: -webkit-linear-gradient(
  left,
  #000000ff,
  #000000ff calc(100% - @tint-end),
  #00000000 calc(100% - @tint-start)
);

@both: -webkit-linear-gradient(
  left,
  #00000000 @tint-start,
  #000000ff @tint-end,
  #000000ff calc(100% - @tint-end),
  #00000000 calc(100% - @tint-start)
);

.hasPrevious {
  -webkit-mask-image: @prev;
  mask-image: @prev;
}

.hasNext {
  -webkit-mask-image: @next;
  mask-image: @next;
}

.hasBoth {
  -webkit-mask-image: @both;
  mask-image: @both;
}

[data-size="large"] {
  &.tiles {
    .tiles-container();
  }
}

[data-size="compact"] {
  &.tiles {
    .tiles-container(@compact-scale);
  }
}
