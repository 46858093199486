@color: #e8e8e8;

.root {
  border-radius: 4px;
  border: none;
  color: @color;
  padding-left: 2px;
  padding-right: 2px;

  &:hover {
    color: @color;
  }
}
