@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';

.root {
  .scrollbar();

  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 10em;
  overflow-y: auto;
  padding-right: 0.5em;
  width: 100%;

  span {
    padding-right: 1em;
    text-align: right;
    width: 30%;
  }

  label {
    align-items: center;
    display: flex;
    margin: 0;
    max-width: 100%;
    padding: 0.5em;
    width: 50%;
  }

  input, select {
    width: 70%;
  }
}

