@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';

.item {
  align-items: center;
  display: flex;
  list-style-type: none;
  margin-right: 2em;

  &:last-child {
    margin-right: 0;
  }

  > a, button {
    background: transparent;
    border: none;
    border-bottom: solid 2px transparent;
    border-radius: 0;
    color: @body-color;
    display: block;
    font-family: @weplayed-special-font;
    font-size: 0.9em;
    font-weight: normal;
    margin-bottom: -2px;
    padding: 0;
    padding-bottom: 0.5em;
    padding-top: 0.5em;
    white-space: nowrap;

    &.active {
      border-bottom-color: @weplayed-accent;
    }
  }
}

.root {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;

  &.underline {
    border-bottom: solid 1px fade(@body-color, 10%);
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  touch-action: none;
}

.extra {
  list-style-type: none;
  margin-left: auto;
}

.help {
  .extra();

  width: auto;
  text-indent: 0;

  svg {
    margin-right: 0.5em;
    height: 1.25em;
  }
}

.helpContainer {
  max-height: 0;
  overflow: hidden;
  transition: max-height @animation-duration;
}

.helpContent {
  padding: 1em 0 0;
}

