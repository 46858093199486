@import 'cms/styles/variables.less';

:root {
  --game-header-height: 50px;

  #media-breakpoint-down('sm', {
    --game-header-height: 40px;
  })
}

.root {
  height: var(--game-header-height);
  overflow: hidden;
  width: 100%;
  color: white;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;

  &.center {
    justify-content: center;
  }

  a {
      z-index: 20;
  }
}

.link {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.text, .extra {
  font-size: 1.2em;
  flex: 1;
  overflow: hidden;

  #media-breakpoint-down('sm', {
    font-size: 0.9em;
  });
}

.extra span {
  display: block;
  white-space: nowrap;
  padding-left: 20px;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
}
