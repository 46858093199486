@import '~common/styles/variables.less';

@background: #00000055;
@color: #ffffff;

.root {
  background-color: @background;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  // Grid items
  > div, button {
    cursor: pointer;
    height: 100%;
    padding: 5px 15px;
    border: none;
    background: transparent;
  }

  .progress {
    display: flex;
    flex: 1;
    height: 100%;
    padding: 0 15px;

    input {
      height: 100%;
    }
  }

  svg {
    color: @color;
    cursor: pointer;
  }

  #media-breakpoint-down(@layout-toggle, {
    padding-bottom: 0px;

    svg {
      height: 20px;
      width: 20px;
    }
  });
}