@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';

.disabled {
  opacity: 0.3;
  pointer-events: none;
  touch-action: none;
}

.header {
  flex-direction: column;

  :global(.modal-title) {
    text-align: left;
  }
}

.tabs {
  border-bottom: none;
  bottom: calc(-1em - 2px);
  position: relative;
}

.section {
  margin: 2em 0 0;
  padding: 0;

  &.border {
    border-top: solid 1px fade(@body-color, 10%);
    padding-top: 2em;
  }
}

.row {
  margin-bottom: 0.5em;

  &:last-child {
    margin-bottom: 0;
  }

  h4 {
    font-family: @weplayed-text-font;
    font-size: 1.2em;
    margin-bottom: 1em;

    .sectionNote {
      font-size: 0.8em;
      font-style: oblique;
      font-weight: normal;
      margin-left: 0.5em;
    }
  }

  dl {
    display: flex;
    flex-direction: row;

    > dt {
      flex: 1;
      font-weight: normal;
    }

    > dd {
      width: 15em;
      margin-left: 3em;
    }
  }
}

.theme,
.name {
  position: relative;
  width: 70%;
}

.name {
  margin-top: 1em;
}

.theme {
  .type {
    margin-bottom: 0;
  }

  select[disabled] {
    -moz-appearance: none;
    -webkit-appearance: none;

    &::-ms-expand {
      display: none;
    }
  }
}

.type.loading::after {
  .button-mask-position();
  .button-mask-repeat();
  .button-mask-size(80%);
  .button-mask-url('~images/spinner.svg');

  background-color: @body-color;
  bottom: 0;
  content: '';
  height: 2.5em;
  position: absolute;
  right: -2.5em;
  width: 2.5em;
}

.selector {
  display: flex;
  flex-direction: row;
  margin-top: 1em;

  &:empty {
    display: none;
  }

  .checkboxes {
    margin-right: 2em;
  }

  .checkbox + .checkbox {
    margin-top: 0.5em;
  }
}

.warning {
  align-items: center;
  color: @warning;
  display: flex;
  font-style: oblique;
}

.publicText,
.privateText {
  padding-left: 1.6em;
  position: relative;

  &::after {
    .button-mask-position(center);
    .button-mask-repeat();
    .button-mask-size(80%);
    .button-mask-url('~@mdi/svg/svg/eye-outline.svg');

    background-color: @body-color;
    bottom: 0;
    content: '';
    height: 1.4em;
    left: 0;
    position: absolute;
    width: 1.4em;
  }
}

.privateText::after {
  .button-mask-url('~@mdi/svg/svg/eye-off-outline.svg');
}

.exist {
  font-style: oblique;
  margin-left: 0.5em;
}

.info {
  display: block;
  font-size: 0.7em;
  text-align: left;
}

.sort {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-left: auto;

  .sortName {
    color: @body-color;
    margin-right: 1em;
  }

  .menu {
    background-color: @body-bg;
  }

  .item {
    color: @body-color;

    &:hover:not(:disabled) {
      background-color: fade(@body-color, 10%);
    }
  }

  button {
    .masked-button('~@mdi/svg/svg/sort.svg', 60%);

    height: 2.5em;
    width: 2.5em;
  }
}

.drag {
  opacity: 0.5;
  z-index: 20000;
}

.auto,
.manual {
  padding-left: 1.6em;
  position: relative;

  &::after {
    .button-mask-position(center);
    .button-mask-repeat();
    .button-mask-size(80%);
    .button-mask-url('~@mdi/svg/svg/lightning-bolt.svg');

    background-color: @body-color;
    bottom: 0;
    content: '';
    height: 1.4em;
    left: 0;
    position: absolute;
    width: 1.4em;
  }
}

.manual::after {
  .button-mask-url('~@mdi/svg/svg/lightning-bolt-outline.svg');
}
