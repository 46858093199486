@import '~cms/styles/variables.less';
@animation: 100ms;
@compact-scale: (1 / 1.5);

.fadeOut() {
  opacity: 0;
  transition: opacity ease-in @animation;
}

.fadeIn() {
  opacity: 1;
}

.disabled() {
  filter: grayscale();
  opacity: 0.2;
  pointer-events: none;
  touch-action: none;
}

.button() {
  align-items: center;
  background-color: transparent;
  border: 0;
  display: flex;
  height: @tile-top-size;
  justify-content: center;
  outline: none;
  padding: 0;
  width: @tile-top-size;

  &:active {
    outline: none !important;
  }
}

.blocked() {
  .absolute-full();

  align-items: center;
  background-color: fade(#000, 70%);
  display: flex;
  justify-content: center;
  pointer-events: none;
  text-align: center;
  touch-action: none;
  user-select: none;
}
