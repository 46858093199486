@rulerHeight: 8px;
@knobRadius: 9px;

@mobileRulerHeight: 10px;
@mobileKnobRadius: 20px;

.root {
  display: flex;
  flex-direction: column;
  user-select: none;
}

.track {
  height: @rulerHeight;
  position: relative;
  width: 100%;
  margin: (@knobRadius * 2 - @rulerHeight) / 2 0;
  background-color: rgba(255, 255, 255, 0.1);

  @media (max-width: 768px) {
    height: @mobileRulerHeight;
    margin: (@mobileKnobRadius * 2 - @mobileRulerHeight) / 2 0;
  }
}

.knob {
  height: @knobRadius * 2;
  margin-left: -@knobRadius;
  position: absolute;
  top: @rulerHeight / 2 - @knobRadius;
  width: @knobRadius * 2;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  pointer-events: none;

  @media (max-width: 768px) {
    height: @mobileKnobRadius * 2;
    margin-left: -@mobileKnobRadius;
    top: @mobileRulerHeight / 2 - @mobileKnobRadius;
    width: @mobileKnobRadius * 2;
  }
}
