@import '~cms/styles/variables.less';

.root {
  opacity: 0;
  transition: opacity @animation-duration linear;
}

.loaded {
  opacity: 1;
}
