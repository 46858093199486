@import '~cms/styles/variables.less';

.root {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.at {
  flex: 1;
  font-size: 0.8em;
  max-width: 50px;
  min-width: 1em;
  text-align: center;
}

.score {
  flex: 1;
  font-family: @weplayed-special-font;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
}

.main {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1;
  font-size: 1.3em;
  justify-content: space-between;

  & :first-child {
    text-align: right;
  }
}

.date {
  display: none;
  font-size: 0.8em;
}

.info {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 6em;

  &.withDate .date {
    display: block;
  }
}
