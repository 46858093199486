.root {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.visible {
  opacity: 1;
}

.pixel {
  height: 1px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  touch-action: none;
  user-select: none;
  width: 1px;
}
