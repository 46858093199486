@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';

.root {
  overflow: hidden;
  position: relative;
  transition: transform @animation-duration, z-index @animation-duration;
  z-index: 0;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &:hover,
  &.hover {
    .animated {
      animation-play-state: running;
      display: block;
    }
  }
}

.animated {
  animation: fadeIn @animation-duration paused;
  background: no-repeat center center;
  background-size: cover;
  display: none;
  inset: 0 0 0 0;
  pointer-events: none;
  position: absolute;
  touch-action: none;
  transition: opacity @animation-duration;
}

.area {
  animation: fadeIn .2s normal forwards ease-out 1;
  backdrop-filter: blur(5px);
  background-color: #000000cc;
  inset: 0 0 0 0;
  mask: url('images/black.png') no-repeat exclude, linear-gradient(#000 0 0);
  opacity: 0;
  position: absolute;
  transition: mask-size .2s, mask-position .2s;
}

.children {
  inset: 0 0 0 0;
  position: absolute;
}