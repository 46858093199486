@import '~cms/styles/variables.less';

.header {
  position: relative;

  > span {
    font-weight: normal;
    margin-left: 0.25em;

    &[data-size="smaller"] {
      font-size: 0.75em;
    }
  }
}

h2.header {
  margin: 0 0 1em 0;

  .user {
    display: inline-block;
    margin: -0.25em 0.5em -0.25em 0;
    width: 1.5em;
    height: 1.5em;
  }
}

h3.header {
  margin: 0 0 1em 0;
  font-family: @weplayed-special-font;
  font-size: 1.4em;
  font-weight: 400;
}

h4.header {
  margin: 0 0 1em 0;
  font-family: @weplayed-special-font;
  font-size: 1.4em;
  font-weight: 400;
}
