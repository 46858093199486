@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';

.root {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.content {
  flex-direction: column;
  flex: 1;
  max-width: calc(100% - @menu-width-collapsed);
  padding: @admin-content-padding-y @admin-content-padding-x;
  position: relative;

  &.full {
    padding: 0;
  }
}

.back {
  .masked-button('~@mdi/svg/svg/chevron-left.svg');

  height: 2em;
  left: 1em;
  position: absolute;
  top: 2em;
  width: 2em;
}

.dropdown {
  z-index: 2000;
  box-shadow: fade(@body-color, 20%) 0 0 2px;
}
