@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';

:root {
  --tiny-header-height: @header-height;
}

.root {
  align-items: center;
  background-color: @header-background;
  display: flex;
  flex-direction: row;
  height: @header-height;
  position: sticky;
  top: 0;
  z-index: @header-zindex;
}

.downloads,
.help,
.menu,
.logo,
.school,
.search,
.settings,
.profile {
  align-items: center;
  background-color: transparent;
  border: 0;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  line-height: 0;
  outline-offset: -1px;
  overflow: hidden;
  padding: 0;
  text-indent: -1000px;
  width: @header-height;
}

.menu {
  .masked-button('~@mdi/svg/svg/menu.svg', 50%);

  &::after {
    background-color: @header-color;
  }

  &.back::after {
    .button-mask-url('~@mdi/svg/svg/chevron-left.svg');
  }
}

.logo {
  @logo-size: 65%;
  background-image: url('~images/logo-symbol-gray.svg');
  background-position: ((@menu-width-collapsed - @header-height * @logo-size / 100) / 2) center;
  background-repeat: no-repeat;
  background-size: auto @logo-size;
  padding-left: @menu-width-collapsed;
  text-indent: 0;
  width: auto;

  span {
    text-indent: -100em;
    background-image: url('~images/weplayed-text.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 9.5em;
    height: 100%;
  }
}

.school {
  border-left: solid 1px @header-color;
  font-size: 1em;
  font-weight: bold;
  height: @input-height;
  margin-left: 1em;
  text-indent: 0;
  text-transform: uppercase;
  width: auto;

  img {
    height: 80%;
    margin: 0 1em;
    object-fit: contain;
    width: 2.5em;
  }
}

.mc {
  border-left: solid 1px @header-color;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  font-weight: bold;
  height: @input-height;
  margin-left: 1em;
  padding-left: 1em;
  width: auto;
}

.profile {
  margin-right: 0.5em;
}

.profile-menu {
  background-color: @header-background;
  font-size: 0.8em;
  padding: 0 1em;
  z-index: 10000;

  ul {
    border-top: solid 1px @header-color;
    margin: 0;
    padding: 0.5em 0;

    &:first-child {
      border-top: none;
    }

  }

  li {
    list-style-type: none;
    margin: 0;
    padding: 0.5em 0;

    &.name {
      font-weight: bold;
      padding-bottom: 0;
    }

    &.email {
      font-size: 0.9em;
      opacity: 0.7;
      padding-top: 0;
    }
  }

  a {
    display: block;
    margin: -0.5em -1em;
    padding: 0.5em 1em;
    transition: background-color @header-animation-duration;

    &:hover {
      background-color: fade(@header-color, 20%);
      color: @header-color;
    }
  }
}

.social {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  li {
    width: 2em;
  }

  a {
    .masked-button(@size: 75%);

    display: block;
    height: 2em;
    margin: 0;
    width: 2em;

    &::after {
      background-color: @header-color;
    }
  }

  .facebook::after {
    .button-mask-url('~@mdi/svg/svg/facebook.svg');
  }

  .linkedin::after {
    .button-mask-url('~@mdi/svg/svg/linkedin.svg');
  }

  .instagram::after {
    .button-mask-url('~@mdi/svg/svg/instagram.svg');
  }

  .twitter::after {
    .button-mask-url('~@mdi/svg/svg/twitter.svg');
  }
}

.search {
  .masked-button('~@mdi/svg/svg/magnify.svg', 90%);

  border-radius: @input-border-radius;
  height: @input-height;
  margin: 0 0.5em;
  transition: opacity @animation-duration;
  width: @input-height;

  &:focus, &:active, &:focus:active {
    outline: none;
  }

  &::after {
    background-color: @header-color;
    -webkit-mask-position-y: 100%;
  }
}

.help {
  .masked-button('~@mdi/svg/svg/help-circle-outline.svg', 60%);

  height: @input-height;
  margin-right: 0.5em;
  width: @input-height;
}

.downloads {
  height: @input-height;
  margin-right: 0.5em;
  width: @input-height;
}

.settings {
  .masked-button('~@mdi/svg/svg/cog.svg', 60%);

  height: @input-height;
  margin-right: 0.5em;
  width: @input-height;
}

.divider {
  border-right: solid 1px @header-color;
  height: @input-height;
  margin-left: 0.5em;
  margin-right: 0.5em;
  width: 1px;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.form {
  flex: 1;
  opacity: 0;
  pointer-events: none;
  touch-action: none;
  transform: translateY(-100%);
  transition: opacity @animation-duration, transform @animation-duration;

  :global(.input-group) {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    margin: 0 auto;
  }

  input,
  input:focus {
    background-color: @body-bg;
    border-color: @body-bg;
    box-shadow: none;
    color: @body-color;
    flex: 1;
    max-width: 30em;
    width: 30%;
  }

  button {
    .masked-button('~@mdi/svg/svg/magnify.svg', 80%);

    background-color: darken(@body-bg, 5%);
    height: @input-height;
    margin-left: 2px;
    width: @input-height;

    &::after {
      background-color: @header-color;
    }
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
    touch-action: auto;
    transform: translateY(0);

    & + .search {
      opacity: 0;
      pointer-events: none;
      touch-action: none;
    }
  }
}

