@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';
@import '../../mixins.less';

@item-height: 6em;

.selecting .item.disallowed,
.disabled {
  .disabled();
}

.blocked {
  .blocked();

  svg {
    fill: @danger;
    height: 70%;
    width: 70%;
  }
}

.thumbnail {
  .tile-bg(200);

  border: solid 1px fade(@body-bg, 50%);
  display: block;
  height: (@item-height - 1.5em);
  position: relative;
  transition: height ease-in @animation,
              width ease-in @animation;
  width: ((@item-height - 1.5em) / @video-proportions);

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.name {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
}

.live {
  font-size: 0.75em;
  left: 0.5em;
  position: absolute;
  top: 0.5em;
}

.game {
  margin: 0;
}

.control {
  .fadeOut();

  background-color: transparent;
  text-align: center;

  &.visible {
    .fadeIn();
  }
}

.item:hover .control {
  .fadeIn();
}

.selecting .item .control {
  .fadeOut();
}

.select {
  display: none;
  height: 100% !important;
  left: 0;
  overflow: hidden;
  padding: 0 !important;
  position: absolute;
  top: 0;
  width: 100%;

  button {
    background-color: transparent;
    border: none;
    height: 100%;
    padding: 0;
    text-indent: -100vw;
    width: 100%;
  }
}

.item {
  transition: background-color ease-in @animation;

  &[draggable="true"] {
    cursor: move;
  }

  td {
    border-bottom: solid 1px fade(@body-color, 5%);
    height: @item-height;
    overflow: hidden;

  }

  &:first-child td {
    border-top: solid 1px fade(@body-color, 5%);
  }

  &.hidden {
    visibility: collapse;
  }

  &.drop {
    background-color: fade(@body-color, 10%);

    td {
      height: @item-height;
      padding: 0;
      position: relative;

      span {
        align-items: center;
        display: flex;
        font-size: 0.8em;
        justify-content: center;
        left: 50%;
        position: absolute;
        right: 50%;
        text-transform: uppercase;
        transform: translate(-50%, -50%);
        white-space: pre;
      }
    }

    &.nodrop {
      background-color: inherit;

      span {
        display: none;
      }
    }
  }

  &.odd {
    background-color: fade(@body-color, 2%);
  }

  &:not(.noEffect) {
    td {
      transition: height ease-in @animation,
                  padding ease-in @animation;
    }

    &:hover {
      background-color: fade(@body-color, 7%);
    }

    &:focus {
      outline: dotted 1px @body-color;
    }

    &[tabindex] {
      cursor: pointer;
    }
  }

  &.highlighted:not(.noEffect) {
    // background-color: fade(@weplayed-background, 80%);
    background-color: @weplayed-background;
  }

  .checkbox {
    padding: 0;
    text-align: center;
    vertical-align: middle;
    width: 3em;

    button {
      .fadeOut();

      align-items: center;
      background-color: transparent;
      border: none;
      display: flex;
      height: 100%;
      justify-content: center;
      margin: 0;
      padding: 0;
      width: 100%;

      * {
        touch-action: none;
        pointer-events: none;
      }
    }
  }

  &.disallowed .checkbox button {
    opacity: 0;
    touch-action: none;
    pointer-events: none;
  }
}

.dragover .item:hover {
  background-color: inherit;
}

.selecting .item,
.item:hover,
.item:focus-within {
  &:not(.disallowed) {
    .checkbox button {
      .fadeIn();
    }
  }
}

.item:focus-within .control {
  .fadeIn();
}

.selecting {
  .control {
    .fadeOut();
  }

 .select {
    display: block;
  }
}

.avatar {
  height: 1.5em;
  width: 1.5em;
}

.gameLabel {
  margin: 0;
}

.base {
  background-color: @yellow;
  border-radius: 0.25em;
  box-shadow: 0 0 0.25em @body-color;
  color: @black;
  font-size: 0.5em;
  font-weight: bold;
  padding: 0 0.25em;
  position: absolute;
  right: 1em;
  text-transform: uppercase;
  top: 1em;
}

.algo {
  .masked-button('~@mdi/svg/svg/lightning-bolt-outline.svg', 70%);

  background-color: @tile-background-color;
  bottom: 0.25em;
  display: block;
  height: 1.5em;
  position: absolute;
  right: 0.25em;
  width: 1.5em;

  &::after {
    background-color: darken(@body-color, 20%);
  }

  &.auto::after {
    .button-mask-url('~@mdi/svg/svg/lightning-bolt.svg');
    background-color: @body-color;
  }
}

[data-size="compact"] {
  @size: (@item-height * @compact-scale);

  .item {
    td {
      font-size: 0.8em;
      height: @size;
    }
  }

  .name {
    -webkit-line-clamp: 1;
  }

  .tdThumbnail {
    width: (@size / @video-proportions);
    user-select: none;
  }

  .thumbnail {
    height: (@size - 0.5em);
    width: ((@size - 0.5em) / @video-proportions);
  }
}

.extra {
  animation: fadeIn @animation-duration forwards;
  opacity: 0;
  padding: 0 !important;
}
