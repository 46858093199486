@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';

.root {
  display: flex;
  flex-direction: row;

  dl {
    flex: 1;
  }

  dd {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 1em;
  }
}

.avatar {
  height: 1.5em;
  margin-right: 0.5em;
  width: 1.5em;
}

.theme {
  border-bottom: solid @modal-header-border-width @modal-header-border-color;
  font-style: oblique;
  margin-bottom: @modal-inner-padding;
  padding-bottom: @modal-inner-padding;
}

.algo {
  .masked-button('~@mdi/svg/svg/lightning-bolt-outline.svg');

  display: block;
  height: 1.5em;
  width: 1.5em;

  &.auto::after {
    .button-mask-url('~@mdi/svg/svg/lightning-bolt.svg');
  }
}
