@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';

.textbox {
  border: none;
  box-shadow: none;
  color: @body-color;
  font-size: 1.2em;
  min-height: 6em;
  text-shadow: none;
  width: 100%;

  &:global(__highlighter) {
    max-height: 10em;
  }

  &:global(__input) {
    background-color: @weplayed-foreground;
    border: none;
    box-shadow: none;
    color: @body-color;
    font-size: 1.2em;
    height: 10em;
    resize: none;
    text-shadow: none;
    width: 100%;
  }

  &:global(__suggestions) {
    background-color: @weplayed-background  !important;
    border-radius: 10px;
    border: 2px solid @weplayed-secondary-cta-hover;
    padding: 5px;
    z-index: 1000 !important;
  }

  &:global(__suggestions__item) {
    border: 2px solid transparent;
    border-radius: 7px;
    margin: 3px;
    padding: 3px;
  }

  &:global(__suggestions__item--focused) {
    border: 2px solid @body-color;
  }

}

.editable {
  cursor: text;
}

.textboxPlaceholder {
  color: @weplayed-secondary-cta-hover;
}

.suggestionItem {
  border-radius: 5px;
  color: @body-color;
  padding: 3px;

  &.suggestionItemTag {
    background-color: @weplayed-blue-dark;
  }
}

.tag {
  background-color: @weplayed-blue-dark;
  border-radius: 4px;
  border: none;
  color: @body-color;
  padding-left: 2px;
  padding-right: 2px;

  &:hover {
    color: @body-color;
  }
}

.team {
  border-radius: 4px;
  border: none;
  color: @body-color;
  padding-left: 2px;
  padding-right: 2px;

  &:hover {
    color: @body-color;
  }
}

.player {
  background-color: @weplayed-secondary-cta-hover;
  border-radius: 4px;
  border: none;
  color: @body-color;
  padding-left: 2px;
  padding-right: 2px;

  &:hover {
    color: @body-color;
  }
}

.light {
  color: #333 !important;
}

.container {
  position: relative;
  width: 100%;

  &.disabled {
    pointer-events: none;
    touch-action: none;
    filter: grayscale();
    opacity: 0.2;
  }
}

.counter {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  bottom: 10px;
  color: @body-color;
  font-size: 1em;
  opacity: 0.3;
  padding: 0 5px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  user-select: none;
}

.plainText {
  color: @body-color;
}

.placeholder {
  background-color: @weplayed-foreground;
  bottom: 0;
  color: fade(@body-color, 50%);
  font-size: 1.2em;
  left: 0;
  padding: 2px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;

  svg {
    fill: fade(@body-color, 50%);
  }
}
