@import "~cms/styles/variables.less";

.iframe {
  height: 0;
  overflow: hidden;
  width: 100%;
}

.loading {
  opacity: 50% !important;
}

.loader {
  height: 18px;
  margin-right: 5px;
}

.widthContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  button {
    margin-left: 5px;
  }
}

.widthInput {
  width: 6em;
  height: 2em;
}

.widthSetButton {
  width: 3em;
  height: 2em;
}

.textArea {
  width: 100%;
  background-color: rgb(230, 230, 230);
  border: 2px solid @weplayed-blue-dark;
  border-radius: 5px;
  resize: none;
  margin-top: 10px;
}