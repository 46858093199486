@import '~cms/styles/mixins.less';

@keyframes bounce {
  0% {
    opacity: 0.2;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0.2;
    transform: scale(0.8);
  }
};

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  touch-action: none;
  pointer-events: none;
  transition: opacity 200ms linear;

  &.dark div::after {
    background-color: @body-color;
  }

  div {
    height: 100px;
    position: relative;
    width: 100px;

    &::after {
      .button-mask('~images/logo-symbol-open-black.svg');
      background-color: white;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &.loading {
    opacity: 1;

    div {
      animation: bounce 2s cubic-bezier(0.785, 0.135, 0.150, 0.860) infinite both;
    }
  }
}
