@import 'cms/styles/variables.less';

.org {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-top: 30px;
    text-align: center;
  }

  img.logo {
    margin: 10px auto;
    height: 100px;
    display: block;
  }
}

.orMessage {
  padding-top: 30px;
  padding-bottom: 10px;
}

.logo {
  max-height: 150px;
}

