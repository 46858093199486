@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';

@duration: @animation-duration;

.root {
  bottom: 0;
  position: fixed;
  right: 0;
  top: var(--tiny-header-height);
  width: 50%;
  z-index: 301;

  .slider {
    background-color: @weplayed-section-background;
    border-bottom-left-radius: @border-radius;
    border-right: none;
    border-top-left-radius: @border-radius;
    border: @weplayed-section-border;
    display: flex;
    flex-direction: column;
    height: 100%;
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 0;
    transition: right @duration 200ms, opacity @duration 200ms ease-in;
    width: 100%;
  }

  &.hidden {
    pointer-events: none;
    touch-action: none;

    .slider {
      transition: right @duration 0ms, opacity (@duration/2) 0ms ease-in;
      opacity: 0;
      right: -100%;
    }
  }
}

.disabled {
  filter: grayscale();
  opacity: 0.2;
  pointer-events: none;
  touch-action: none;
}

.title {
  border-bottom: @weplayed-section-border;
  margin-bottom: 0;
  padding: (@weplayed-section-padding / 2) @weplayed-section-padding;

  .titleText {
    margin-bottom: 0;
  }
}

.help {
  display: inline-flex;
  margin-left: 0.7em;
  vertical-align: -0.15em;

  svg {
    height: 0.9em;
    width: 0.9em;
  }
}

.body {
  overflow: hidden;
}

.backdrop {
  background-color: @weplayed-background-semitransparent;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  touch-action: none;
  transition: opacity @duration;
  z-index: 300;

  &.active {
    opacity: 1;
    pointer-events: all;
    touch-action: auto;
  }
}

.close {
  .masked-button('~@mdi/svg/svg/close.svg', 100%);

  height: 2em;
  position: absolute;
  right: 1em;
  top: 1em;
  width: 2em;
}

.section {
  padding: @weplayed-section-padding;

  & + &:not(.no-border) {
    border-top: @weplayed-section-border;
  }

  & + &.no-border {
    padding-top: 0;
  }

  &.rest {
    flex: 1;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > * {
    margin-left: 2em;

    &:first-child {
      margin-left: 0;
    }
  }
}

