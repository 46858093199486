@import '~cms/styles/variables.less';

.root {
  width: 150px;
  background-color: @body-bg;
  border-radius: 0.25em;
  font-size: 0.8em;
  overflow: hidden;
}

.group {
  border-top: solid 1px fade(@body-color, 20%);

  &:first-child {
    border-top: 0;
  }

  &:empty {
    display: none;
  }
}

.button {
  background-color: transparent;
  color: @body-color;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: none;
  width: 100%;
  padding: 0.25em 1em;

  > :first-child {
    margin-right: 0.75em;
  }

  &:hover:not(:disabled) {
    background-color: fade(@body-color, 10%);
  }

  &:disabled {
    opacity: 0.2;
  }

  svg {
    fill: @body-color;
    width: 18px;
    height: 18px;
    margin: 4px 0;
  }

  &.danger {
    color: red;

    svg {
      fill: red;
    }
  }
}
