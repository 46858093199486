
.avatar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  > * {
    margin-right: 10px;
  }
}

.email {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  font-size: 1.4em !important;
  width: 254px;
  padding: 5px !important;
  line-height: 1.5em;

  span {
    margin-right: auto;
  }

  svg {
    height: 30px;
    margin-left: 0;
    margin-right: 17px;
    width: 30px;
  }
}

.or {
  margin: 20px 0;
}
