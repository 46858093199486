@import '~cms/styles/variables.less';

.root {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 1em;
  margin-top: 0.25em;
  white-space: nowrap;

  .team {
    background-color: @body-color;
    border-radius: 50%;
    height: 1.5em;
    margin: 0 0.5em;
    object-fit: contain;
    width: 1.5em;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}