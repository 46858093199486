.root {
  position: relative;
}

.ads {
  @shiftV: 2%;
  @shiftH: (@shiftV * 9 / 16);

  position: absolute;
  height: 10%;

  img {
    height: 100%;
  }

  &:not(.left):not(.right) {
    left: 50%;
    transform: translateX(-50%);
  }

  &:not(.top):not(.bottom) {
    top: 50%;
    transform: translateY(-50%);
  }

  &.top {
    top: @shiftV;
  }

  &.left {
    left: @shiftH;
  }

  &.right {
    right: @shiftH;
  }

  &.bottom {
    bottom: @shiftV;
  }
}
