@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';

.root {
  display: block;
  user-select: none;
  width: 16em;

  &.global {
    z-index: 1400;
  }
}

.menu {
  pointer-events: all !important;
}

.item {
  text-align: left;
  display: inline-flex;
  flex-direction: column;

  b {
    display: block;
  }

  span {
    display: block;
    font-size: 0.9em;
    line-height: 1.2em;
    margin-top: 0.2em;
    white-space: normal;
  }
}

.itemWithDescription {
  font-size: 0.8em;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
  }

  &::after {
    vertical-align: -1em;
  }
}

.form {
  display: flex;

  > div {
    margin-right: 3em;

    > button {
      padding-left: 0;
    }
  }

  > button {
    width: 10em;
  }
}
