.org {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-top: 30px;
    text-align: center;
  }

  img.logo {
    margin: 10px auto;
    height: 100px;
    display: block;
  }
}

.header {
  padding-bottom: 10px;
}

.invitation-error {
  margin: 40px 0;
  text-align: center;
}