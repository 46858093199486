@import '~cms/styles/variables.less';

.avatar-container {
  display: inline-block;
  position: relative;
  height: 35px;
  width: 35px;

  .avatar-image-container {
    align-items: center;
    background-color: @weplayed-icons;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    img {
      height: 100%;
      object-position: center;
      object-fit: cover;
      width: 100%;
    }
  }

  .avatar-svg {
    height: 100%;
    width: 100%;

    text {
      fill: @weplayed-modal-text;
      font-family: @weplayed-text-font;
    }
  }

  b.avatar-badge {
    background-color: @weplayed-blue-light;
    border-radius: 50%;
    box-shadow: 0 2px 4px @weplayed-background;
    bottom: 0;
    height: 20%;
    position: absolute;
    right: 0;
    width: 20%;
  }

  &.large {
    height: 100px;
    width: 100px;
  }

  &.x-large {
    height: 150px;
    width: 150px;
  }
}
