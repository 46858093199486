.root {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;

  &:disabled {
    cursor: default;
  }

  @size: 1em;

  svg {
    fill: #fff;
    height: @size;
    width: @size;

    &.padded {
      margin-left: 10px;
    }
  }

  &.over {
    @size: 1.6em;
    position: relative;

    svg {
      height: @size;
      width: @size;
      left: 50%;
      margin-left: 0;
      position: absolute;
      top: 50%;
      transform: translate(-(@size / 2), -(@size / 2));
    }
  }
}
