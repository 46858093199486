@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';

.root {
  width: 50em;
}

.choices {
  padding: 2em;
}

.help {
  font-size: 0.9em;
  margin: 0;
  padding-left: 1em;

  li {
    text-align: left;

    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }
}

.table {
  td {
    padding-bottom: 0.25em;
    padding-top: 0.25em;
    vertical-align: middle;

    &.tdHelp {
      font-size: 0.8em;
      padding-bottom: 0;
      padding-top: 3em;
    }

    &.tdName {
      padding-left: 2em;
    }

    &.tdPublish {
      width: 10em;
    }

    &.tdPin {
      width: 2em;
    }
  }
}

.edit {
  padding-right: 0.5em;
  white-space: pre;

  span {
    .masked-button('~@mdi/svg/svg/menu-right.svg', 130%);
    display: inline-block;
    height: 1em;
    margin-left: 0.2em;
    transition: transform ease-in @animation-duration;
    width: 1em;
  }

  &.open span {
    transform: rotateZ(90deg);
  }
}

.holder {
  display: flex;
  flex-direction: row;
  padding: 1em 0;

  --items-width: calc(100% * 2 / 3);
  --item-width: calc((100% - 0.5em * 4) / 4);
}

.source {
  display: block;
  flex: 1;
}

.items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: -0.5em;
  margin-left: -0.5em;
  width: var(--items-width);
}

.item {
  aspect-ratio: 16 / 9;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  position: relative;
  width: var(--item-width);

  img {
    filter: brightness(50%);
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }

  button {
    .masked-button('~@mdi/svg/svg/pin-outline.svg', 60%);

    background-color: transparent;
    border-radius: 100em;
    height: 50%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: (50% * @video-proportions);

    &::after {
      content: inherit;
      transition: transform ease-in @animation-duration;
    }

    &[disabled] {
      text-indent: inherit;
      font-weight: 600;
      font-size: 1.2em;
      font-family: @weplayed-special-font;
    }
  }

  &.hidden {
    display: none;
  }

  &.pinned button {
    text-indent: -100em;

    &::after {
      .button-mask-url('~@mdi/svg/svg/pin.svg');

      content: '';
    }
  }

  &[draggable="true"] {
    cursor: move;
    outline: solid 2px white;

    button {
      background-color: @weplayed-accent;

      &::after {
        background-color: white;
        content: '';
        transform: rotateZ(30deg);
      }
    }

    &.pinned button {
      &::after {
        transform: rotateZ(0);
      }
    }

    img {
      filter: inherit;
    }
  }

}

.source {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .help {
    font-size: 80%;
    margin: 1em 1em 0;
    text-align: center;
  }

  .item {
    cursor: move;
    margin: 0;
    outline: solid 2px white;
    width: calc((100% - 0.5em * 2) / 2);

    &:hover {
      .masked-button('~@mdi/svg/svg/cursor-move.svg', 80%);

      &::after {
        background-color: @weplayed-accent;
      }
    }
  }

  &.disabled {
    opacity: 0.2;
    pointer-events: none;

    .item {
      .masked-button('~@mdi/svg/svg/cancel.svg', 80%);
    }

    .help {
      display: none;
    }
  }
}

.option {

}