@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';
@import '../mixins.less';

.table {
  width: 100%;
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;

  thead {
    tr {
      &.spacer {
        background-color: transparent;

        td {
          padding: 0;
          height: 0.5em;
        }
      }

      th {
        background-color: @weplayed-background;

        // &:first-child {
        //   border-bottom-left-radius: 0.25em;
        //   border-top-left-radius: 0.25em;
        // }

        // &:last-child {
        //   border-bottom-right-radius: 0.25em;
        //   border-top-right-radius: 0.25em;
        // }
      }
    }
  }


  td {
    border-top-width: 0;
    vertical-align: middle;
  }

  tr {
    position: relative;
  }

  &[data-size="compact"] {
    th, td {
      padding: 0.25em 0.5em;

      &:first-child {
        padding-left: 1em;
      }

      &:nth-last-child(2) {
        padding-right: 1em;
      }
    }

    th {
      font-size: 0.8em;
      padding-bottom: 0.5em;
      padding-top: 0.5em;
    }
  }
}

.disabled {
  .disabled();
}
