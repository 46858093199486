@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';

.root {
  display: block;
}

.disabled {
  filter: grayscale();
  opacity: 0.1;
  pointer-events: none;
  touch-action: none;
}

.headline {
  background-color: @weplayed-page;
  padding: 0.5em @modal-inner-padding !important;
}

.video {
  margin: 0 -@modal-inner-padding 1em;
  position: relative;
}

.avatar {
  height: 1.5em;
  margin-right: 1em;
  vertical-align: -0.3em;
  width: 1.5em;
}

.description {
  color: @body-color;
}

.dropdown {
  z-index: 1100; // 1050 modal
}

.like {
  opacity: 0;
  pointer-events: none;
  touch-action: none;
  transition: opacity @animation-duration;

  &.liked {
    opacity: 1;
  }
}

.header {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: -@modal-inner-padding -@modal-inner-padding 0;

  &:hover {
    button {
      opacity: 1;
      pointer-events: all;
      touch-action: auto;
    }
  }

  button {
    align-items: center;
    background-color: transparent;
    border: none;
    display: flex;
    height: @tile-top-size;
    justify-content: center;
    outline-offset: -1px;
    padding: @tile-top-control-offset;
    width: @tile-top-size;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  .extra {
    color: @body-color;
    font-size: 0.8em;
    margin-right: auto;
  }
}

.title {
  color: @body-color;
  font-size: 1em;
  font-weight: normal;
  line-height: 1.9em;
  margin-bottom: 0;
  margin-right: auto;
  text-transform: uppercase;
}

.adjust {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  margin-top: 0.5em;
}

.button {
  height: 2.5em;
  margin-left: 1em;
}

.moments {
  padding: 0.5em 2em 0;
}

.moment {
  aspect-ratio: 1/@video-proportions;
  background-color: transparent;
  border: solid 0.5px fade(@body-color, 50%);
  display: block !important;
  margin: 3px 0.5em 6px 3px;
  position: relative;
  width: 8em !important; //stupid react-slick changes width to 100%

  &.active,
  &:active,
  &:focus {
    outline: solid 2px @body-color;
    outline-offset: 1px;
  }

  .thumbnail {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.own::after {
    content: 'OK';
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -50%;
    margin-top: -50%;
    color: green;
  }
}

.collection {
  border-top: solid 0.5px fade(@body-color, 10%);
  margin: 2em -@modal-inner-padding 0;
  padding: 0 @modal-inner-padding;
  width: calc(100% + (@modal-inner-padding * 2));

  .avatar {
    margin-bottom: 0.25em;
    margin-left: @modal-inner-padding;
  }

  .title {
    line-height: 1em;
    margin-right: 0.5em;
  }
}

.collectionHeader {
  margin: 0 -@modal-inner-padding;
  padding-top: 0.5em;
  line-height: 1.5em;

  .title {
    line-height: 1.5em;
  }
}

.crop {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;

    li {
      align-items: center;
      display: inline-flex;
      list-style-type: none;
      margin-right: 1em;
    }

    label {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0;
    }

    input {
      opacity: 0;
      position: absolute;

      &:checked + .ratio {
        background-color: @weplayed-accent;
      }
    }

  }

  .overlay {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 0.3em;
    width: 22em;

    select {
      margin-left: 1em;

      &[disabled] {
        opacity: 0.2;
      }
    }

    label {
      align-items: center;
      display: flex;
      flex-direction: row;
      margin: 0;
    }

    input {
      margin-right: 0.5em;
    }
  }

  .ratio {
    border: solid 1px white;
    cursor: pointer;
    display: inline-block;
    height: 2em;
    margin-right: 0.5em;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 12em;

    button {
      margin-left: 0.5em;
    }
  }
}

.help {
  font-size: 0.8em;
  margin-top: 0.2em;
}

.overlayBlock {
  display: block;
  height: 100%;;
  width: 100%;
}

.overlayPreviews {
  --aspect-ratio: 9/16;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;

  button {
    aspect-ratio: var(--aspect-ratio);
    background-color: transparent;
    background-image: url(images/overlay-bg.jpg);
    background-size: cover;
    border: solid 0.1em #777;
    height: 5em;
    margin: 0.5em 1em 0.5em 0;
    padding: 0;
    position: relative;

    &.selected, &:hover {
      border-color: #fff;
      margin-right: 1em;
    }

    &.selected {
      margin-right: 2em;
    }
  }

  &.folded button::after {
    align-items: center;
    color: #fff;
    content: "⯈";
    display: flex;
    font-size: 1.2em;
    height: 100%;
    justify-content: center;
    position: absolute;
    right: -1.6em;
    top: 0;
    width: 1.5em;
  }
}

.cropbox {
  --box-transition: .2s;
  backdrop-filter: blur(5px);
  background-color: #000000cc;
  color: black;
  inset: 0 0 0 0;
  mask: url('images/black.png') no-repeat center 100% exclude, linear-gradient(#000 0 0);
  opacity: 1;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  touch-action: none;
  transition: mask-size var(--box-transition) ease-out,
              mask-position var(--box-transition) ease-out,
              opacity .2s ease-out;

  &.hidden {
    opacity: 0;
    pointer-events: none;
    touch-action: none;
  }
}

.cropCenter {
  backdrop-filter: none;
  background-color: transparent;
  position: absolute;
}

.cropButton {
  .masked-button("~@mdi/svg/svg/cursor-move.svg", 80%);

  background-color: #000000cc;
  border-radius: 1.5em;
  bottom: 3em;
  cursor: move;
  height: 3em;
  left: 50%;
  margin-left: -1.5em;
  opacity: 0.5;
  outline: none;
  pointer-events: all;
  position: absolute;
  touch-action: auto;
  width: 3em;
  z-index: 1;

  &:hover {
    opacity: 1;
  }
}

.cropbox.hidden + .cropCenter {
  opacity: 0;
}

.momentIdx {
  align-items: center;
  color: white;
  display: flex;
  filter: drop-shadow(0.05em 0.05em 0 black);
  font-size: 2em;
  font-weight: bold;
  height: 100%;
  justify-content: center;
  opacity: 0.5;
  width: 100%;

  &:hover {
    opacity: 0;
  }
}
