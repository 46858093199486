@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';

.root {
  background-color: @header-background;
  display: flex;
  flex-direction: column;
  height: calc(100vh - @header-height);
  justify-content: space-between;
  max-width: @menu-width;
  position: sticky;
  top: @header-height;
  transition: max-width @header-animation-duration;
  width: @menu-width;

  > ul {
    border-bottom: solid 1px fade(@body-color, 10%);
    margin: 0;
    padding: 0;

    > li {
      font-weight: 400;
      list-style-type: none;
      position: relative;

      &:before {
        .button-mask(@size: 2em);

        background-color: @header-color;
        content: '';
        display: block;
        height: 4em;
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        touch-action: none;
        transition:
          -webkit-mask-position @header-animation-duration,
          -webkit-mask-size @header-animation-duration,
          mask-position @header-animation-duration,
          mask-size @header-animation-duration
        ;
        width: @menu-width-collapsed;
      }

      > a {
        align-items: center;
        color: @header-color;
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: flex-start;
        line-height: 1em;
        outline-offset: -1px;
        padding-bottom: ((@menu-height - 1em) / 2);
        padding-left: 5em;
        padding-top: ((@menu-height - 1em) / 2);
        transition: // do not change order
          padding-left @header-animation-duration,
          padding-top @header-animation-duration,
          padding-bottom @header-animation-duration,
          font-size @header-animation-duration,
          background-color @header-animation-duration
        ;

        &:hover {
          background-color: @header-accent-color;
        }

        &.active {
          background-color: lighten(@header-background, 10%);
        }

        span {
          display: block;
          text-align: center;
          transition: width @header-animation-duration;
          width: 0;
        }
      }
    }

    ul {
      max-height: 20em;
      overflow: hidden;
      transition: max-height @header-animation-duration @header-animation-duration;

      li {
        list-style-type: disc;
      }
    }
  }

  &.collapsed {
    @text-size: 0.8em;

    max-width: @menu-width-collapsed;

    > ul {

      ul {
        max-height: 0;
        transition: max-height 0s;
      }

      > li {
        &:before {
          .button-mask-position(center, 30%);
          .button-mask-size(1.8em);
          height: @menu-height-collapsed;
        }

        > a {
          font-size: @text-size;
          padding-top: ((@menu-height-collapsed - 1em) * 0.7) / @text-size;
          padding-bottom: ((@menu-height-collapsed - 1em) * 0.3) / @text-size;
          padding-left: 0;

          span {
            width: 100%;
          }
        }
      }
    }
  }
}

.home::before {
  .button-mask-url('~@mdi/svg/svg/home-outline.svg');
}

.discover::before {
  .button-mask-url('~@mdi/svg/svg/web.svg');
}

.library::before {
  .button-mask-url('~@mdi/svg/svg/filmstrip-box-multiple.svg');
}

.publish::before {
  .button-mask-url('~@mdi/svg/svg/monitor.svg');
}

.clients::before {
  .button-mask-url('~@mdi/svg/svg/account-group-outline.svg');
}

.upload::before {
  .button-mask-url('~@mdi/svg/svg/cloud-upload-outline.svg');
}

.studio::before {
  .button-mask-url('~@mdi/svg/svg/filmstrip.svg');
}

.progress {
  bottom: 5%;
  left: 10%;
  position: absolute;
  right: 10%;

  div {
    background-color: lighten(@body-bg, 10%);
    height: 3px;
    margin-top: 2px;
  }

  span {
    display: block;
    height: 100%;
    overflow: hidden;
    text-indent: -100vw;
  }
}

.digest span {
  background-color: @warning;
}

.upload span {
  background-color: @success;
}

.badge {
  position: absolute;
  left: 75%;
  top: 25%;
  width: auto !important;
  transform: translate(-50%, -50%);
}

.version {
  font-size: x-small;
  opacity: 0.5;
  padding: 0.5em 0;
  text-align: center;
}
