@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';

@width: 10em;
@spacing: 0.5em;
@text-height: 2em;

.root {
  margin-top: 1em;
  max-height: 60vh;
  min-height: 56vh;
  overflow: auto;
  padding: 0 1em 0 0;

  &.disabled {
    filter: grayscale();
    opacity: 0.2;
    pointer-events: none;
    touch-action: none;
  }
}

.index {
  align-items: center;
  color: @body-color;
  display: flex;
  justify-content: center;
  width: 3em;
}

.thumbnail {
  position: relative;
  width: 8em;

  div {
    .tile-bg();

    padding-bottom: (100% * @video-proportions);
    position: relative;

    img {
      border: solid 0.5px @body-bg;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.description {
  color: @body-color;
  padding: 0 2em 0 1em;
  position: relative;
  width: 100%;
}

.menu {
  .masked-button('~@mdi/svg/svg/dots-horizontal.svg');

  cursor: default;
  display: none;
  height: 1.5em;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1.5em;
}

.item {
  border-radius: 0.5em;
  cursor: move;
  display: flex;
  flex-direction: row;
  padding: 0.5em;
  user-select: none;

  &:hover,
  &.hover {
    background-color: fade(@body-color, 10%);

    .menu {
      display: block;
    }
  }
}

.sortableMenu {
  background-color: @body-bg;
  box-shadow: fade(@body-color, 20%) 0 0 2px;
  z-index: 20000;

  button {
    align-items: center;
    background-color: transparent;
    border: none;
    color: @body-color;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0.25em 1em;
    width: 100%;

    &:hover:not(:disabled) {
      background-color: fade(@body-color, 10%);
    }
  }
}

.divider {
  border-top-color: fade(@body-color, 20%);
}

.hidden {
  display: none;
}

.inactive {
  .item {
    cursor: default;
  }

  .menu {
    display: none !important;
  }
  // pointer-events: none;
  // touch-action: none;
}
