@import "~bootstrap-less-port/less/bootstrap.less";
@import "~react-toastify/dist/ReactToastify.css";

@import './variables.less';
@import './common.less';
@import './videojs.css';

@font-face {
  font-family: "DigitalDream";
  src: url("~fonts/DIGITALDREAM.woff2") format("woff2");
}

.debug-border {
    border: red 1px dotted;
}

.app-page {
    min-height: 80vh;
    padding: @app-page-padding 0;
    display: flex;
    flex-direction: column;
}

body.wl .app-page {
    padding: 0;
}

.home-org-team-name-and-logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
}

.home-org-team-name {
    font-size: 1.5em;
    font-family: @weplayed-text-font;

    @media (max-width: 500px) {
        font-size: 1.2em;
    }
}

.home-org-team-logo {
    object-fit: contain;
    object-position: center;
    height: 30px;
    width: 30px;
    margin-left: 5px;
    margin-right: 5px;

    @media (max-width: 500px) {
        width: 25px;
        height: 25px;
    }
}

.home-page-content {
    margin-left: 40px;
    margin-right: 40px;
}

.home-page-section-header-spacer {
    text-align: center;
    padding: 0 20px;
}

.home-page-section-header-logo {
    height: 25px;
    margin-left: 10px;
    vertical-align: -3px;
}

.home-section-spacer {
    width: 100%;
    height: 40px;

    // border: red 1px dotted;
    @media (max-width: 768px) {
        height: 20px;
    }
}

.home-page-section-manage {
    margin-left: 10px;
    vertical-align: 4px;
}

.all-in-line-left-middle {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
}

.all-in-line-space-between-middle {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    //border: blue 1px dotted;
}

.vjs-tech {
    width: 100% !important;
    // border: 2px solid red;
}

.centered-page-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.centered-top-page-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.big-filled-text-input {
    width: 350px;
    border-style: solid;
    border-width: 3px;
    border-color: #1aa0db;
    border-radius: 20px;
    padding: 10px;
    box-shadow: 10px 10px 54px -2px rgba(0, 0, 0, 0.75);
    background-color: #2980a1;
    font-size: 24px;
    outline: none;
    margin-right: 30px;
}

.filled-input::placeholder {
    color: #69C0E1;
}

.player-photo-small {
    object-fit: cover;
    object-position: center;
    height: 65px;
    width: 65px;
    border-radius: 50%;
    margin-right: 15px;
    box-shadow: 10px 10px 54px -2px rgba(0, 0, 0, 0.75);
    border: black 1px solid;
}

.player-photo {
    object-fit: cover;
    object-position: center;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    margin-right: 15px;
    box-shadow: 10px 10px 54px -2px rgba(0, 0, 0, 0.75);
    border: black 1px solid;
}

.moment-cover-photo {
    width: 300px;
    border-radius: 15px;
    box-shadow: 10px 10px 54px -2px rgba(0, 0, 0, 0.75);
}

.pagination {
    >li>a {
        color: @weplayed-primary-cta;
    }

    >li>span {
        color: @weplayed-primary-cta;
    }
}

.page-link {
    margin-right: 5px;
}

.page-link-current {
    margin-right: 5px;
    font-weight: bolder;
    font-size: 1.3em;
}

.tile-page-container {
    width: 100%;
    text-align: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
}

.tile-container {
    text-align: center;
    flex-direction: column;
    justify-content: flex-start;
    display: flex;
    margin-left: 15px;
    margin-right: 15px;
    height: 290px;
    overflow: hidden;
}

.tile-image-wrapper {
    text-align: center;
    flex-direction: column;
    justify-content: flex-end;
    display: flex;
    width: 300px;
    height: 290px;
}

.tile-cover-image {
    width: 300px;
    border-radius: 15px;
    box-shadow: 10px 10px 54px -2px rgba(0, 0, 0, 0.75);
}

.react-swipeable-view-container {
    > div {
        overflow-x: hidden !important;
    }
}

.tile-label {
    position: relative;
    top: -55px;
    border-style: solid;
    border-width: 1px;
    border-color: gray;
    background-color: black;
    opacity: 0.75;
    height: 50px;
    width: 285px;
    margin-left: 8px;
    margin-right: 8px;
    padding: 5px;
    border-radius: 10px;
    text-overflow: ellipsis;
    color: white;
    font-size: 14px;
    letter-spacing: 2px;
}

.slick-track {
    display: flex;
}

.slick-prev:before,
.slick-next:before {
    font-size: 30px;
}

.slick-prev {
    left: -35px;
}

.nav-section-button {
    margin-top: 5px;
    margin-bottom: 15px;
    margin-left: 10px;
}

.avatar-tiny-image {
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.avatar-tiny-icon-container {
    width: 30px;
    height: 30px;
    position: relative;
}

.avatar-tiny-icon {
    position: relative;
    transform: scale(1);
    top: 0.15em;
    left: 0.2em;
}

.header-school-logo {
    object-fit: cover;
    object-position: center;
    height: 35px;
    width: 35px;
}

.like-count-icon {
    margin-right: 8px;
}

.section-title-icon-trailing {
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    top: 4px;
}

.section-title-icon-leading {
    height: 1em;
    transform: scale(1.10);
    position: relative;
    top: 2px;
    margin-right: 10px;
}

.nowrap-button {
    display: flex;
    align-content: space-around;
    flex-wrap: nowrap;
}

/////////////////////////////////////////////////////////////////////
// Collection Tiles
.collection-tile-list-spacer {
    margin-bottom: 30px;
}

.collection-tile {
    position: relative;
    width: @tile-desktop-medium-width;

    @media (max-width: 500px) {
        width: @tile-mobile-medium-width;
    }

    margin-left: 0px;
    margin-right: 15px;
}

.collection-tile-content {
    border-radius: @tile-border-radius;

    position: relative;
    top: 0;
    left: 0;
}

.collection-tile-image {
    width: @tile-desktop-medium-width;
    height: @tile-desktop-medium-height;
    border-radius: @tile-border-radius;

    @media (max-width: 500px) {
        width: @tile-mobile-medium-width;
        height: @tile-mobile-medium-height;
    }

    object-fit: cover;
    object-position: center;
}

.collection-tile-photo-shim {
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: black;
    opacity: 0.6;
    width: @tile-desktop-medium-width;
    height: @tile-desktop-medium-height;
    border-radius: @tile-border-radius;

    @media (max-width: 500px) {
        width: @tile-mobile-medium-width;
        height: @tile-mobile-medium-height;
    }
}

.collection-tile-name {
    text-align: left;
    overflow-wrap: break-word;
    position: absolute;
    left: 0px;
    top: 0px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    font-size: 2em;
    font-weight: 500;

    @media (max-width: 500px) {
        font-size: 1.5em;
    }
}

.collection-tile-likes-count {
    color: @weplayed-overlay-text-light;
    overflow-wrap: break-word;
    position: absolute;
    left: 0px;
    bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    width: 100%;
    font-size: 0.9em;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}

///////////////////////////////////////////////////////////
// Tile legend styles:

.tile-legend {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: flex-end;
}

.tile-legend-moment-count {
    font-family: @weplayed-text-font;
    font-size: 0.9em;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}

.tile-legend-curator-image {
    object-fit: cover;
    object-position: center;
    height: 35px;
    width: 35px;

    @media (max-width: 500px) {
        height: 30px;
        width: 30px;
    }

    border-radius: 50%;
    margin-right: 10px;
}

.tile-legend-curator-image-small {
    object-fit: cover;
    object-position: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-right: 5px;
}

.tile-legend-curator-name {
    padding-bottom: 0;
    margin-bottom: 0;
    white-space: nowrap;
    font-size: 1.1em;
    color: @weplayed-blue-light;
}

.tile-legend-curator-icon {
    margin-right: 5px;
}

.moment-legend-curator-container {
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 10px;
}

.moment-legend-curator-image {
    position: relative;
    top: 10px;
    object-fit: cover;
    object-position: center;
    height: 35px;
    width: 35px;

    @media (max-width: 500px) {
        height: 30px;
        width: 30px;
    }

    border-radius: 50%;
    margin-right: 10px;
}

.moment-legend-curator-icon {
    transform: scale(1.3);
    margin-left: 5px;
    margin-right: 10px;
    position: relative;
    top: 5px;
}

.moment-legend-curator-name {
    line-height: 0.9em;
    padding-bottom: 0;
    margin-bottom: 0;
    white-space: nowrap;
    font-size: 1.1em;
    color: @weplayed-blue-light;
}

.game-legend-curator-icon {
    margin-right: 5px;
}

/////////////////////////////////////////////////////////////////////
// Single selection modal
.single-selection-modal-row {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
}

/////////////////////////////////////////////////////////////////////
// Home screen seasons games
.home-season-header-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    //border: yellow 1px dotted;
    margin-bottom: 10px;
    margin-top: 20px;
    flex-wrap: wrap;
}

.home-season-header-text {
    line-height: 0.8em;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 20px;
    white-space: nowrap;
}

.home-section-header-title {
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.home-season-header-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    //border: red 1px dotted;
    margin-right: 20px;

    .dropdown-button {
        margin-left: 0;
        margin-right: 10px;
    }
}

//////////////////////////////////////////////////////////////////
// Moment viewing
.moment-viewing-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: @weplayed-background-secondary;
    margin: -@app-page-padding 0;

    .wl-button {
        border-radius: 0.5em;
        bottom: 20px;
        box-shadow: 0 1px 12px rgba(0,0,0,.08);;
        color: #fff;
        display: block;
        font-size: 20px;
        font-weight: bold;
        left: 50%;
        margin-top: 2em;
        max-width: 90%;
        padding: 20px 0;
        position: fixed;
        text-align: center;
        transform: translateX(-50%);
        width: 400px;
    }
}

body.wl .moment-viewing-page {
    background-color: transparent;
    margin: 0;
    padding: 20px 0;

    #media-breakpoint-down('sm', {
        padding: 7px;
    });
}

.moment-viewing-content {
    padding-bottom: 10em;
    padding-top: 2em;
    width: 50%;

    @media (max-width: 800px) {
        width: 100%;
    }

    //border: 1px solid red;
}

.moment-viewing-spacer {
    width: 100%;
    height: 70px;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////
// Manage moment in collections dialog

.manage-moment-collections-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.manage-moment-collections-row-checkbox {
    width: 2.0em;
}

.manage-moment-collections-row-label {
    margin-left: 20px;
    font-family: @weplayed-text-font;
    font-size: 1.2em;
    font-weight: bold;
    color: @weplayed-modal-text;

    &:hover {
        color: @weplayed-primary-cta-hover;
    }
}

//////////////////////////////////////////////////
// Sports
.sports-page {
    background-color: @weplayed-background-secondary;
    padding-bottom: 10vh;
}

.sports-list-cover-image {
    border-radius: 10%;
    // box-shadow: 5px 5px 10px 0px rgba(50,50,50,0.5);
}

.sports-missing-cover-add-moment-container {
    position: absolute;
    right: 0;
    top: 0;
    width: 20%;
    height: 20%;
    // border: 1px dotted green;
}

.sports-missing-cover-timeline-container {
    width: 100%;
    // border: 1px dotted blue;
}

.missing-cover-container {
    width: 100%;
    position: relative;
    // border: 1px dotted red;
}

.sports-row-spacer {
    width: 100%;
    height: 5vh;
}

.coming-soon-text {
    font-family: @weplayed-text-font;
    font-size: 1.5em;

    @media (max-width: 768px) {
        font-size: 1.0em;
    }
}

.coming-soon-subscribe-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.coming-soon-subscribe-button {
    margin-top: 40px;
    font-family: @weplayed-text-font;
    font-size: 1.5em;

    @media (max-width: 768px) {
        font-size: 1.0em;
    }
}

.missing-cover-image-inline {
    object-fit: cover;
    object-position: center;
    width: 150px;
    height: auto;
}

.profile-form-avatar-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.profile-form-save-button-container {
    margin-top: 2em;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.signin-links {
    margin-top: 20px;
    text-align: center;
    text-decoration: underline;

    :nth-child(2) {
        margin-left: 20px;
    }
}

.custom-control-label {
    &::after {
        top: 1px;
    }

    &::before {
        top: 1px;
    }
}


.custom-switch .custom-control-label::after {
    top: 3px;
}