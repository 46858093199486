@keyframes text-flicker-in-glow {
  0% {
    background-image: none;
    color: rgba(var(--text-color));
  }
  10% {
    background-image: none;
    color: rgba(var(--text-color));
  }
  10.1% {
    background-image: var(--loader-image);
    color: transparent;
  }
  10.2% {
    background-image: none;
    color: rgba(var(--text-color));
  }
  20% {
    background-image: none;
    color: rgba(var(--text-color));
  }
  20.1% {
    background-image: var(--loader-image);
    color: transparent;
  }
  20.6% {
    background-image: none;
    color: rgba(var(--text-color));
  }
  30% {
    background-image: none;
    color: rgba(var(--text-color));
  }
  30.1% {
    background-image: var(--loader-image);
    color: transparent;
  }
  30.5% {
    background-image: var(--loader-image);
    color: transparent;
  }
  30.6% {
    background-image: none;
    color: rgba(var(--text-color));
  }
  45% {
    background-image: none;
    color: rgba(var(--text-color));
  }
  45.1% {
    background-image: var(--loader-image);
    color: transparent;
  }
  50% {
    background-image: var(--loader-image);
    color: transparent;
  }
  55% {
    background-image: var(--loader-image);
    color: transparent;
  }
  55.1% {
    background-image: none;
    color: rgba(var(--text-color));
  }
  57% {
    background-image: none;
    color: rgba(var(--text-color));
  }
  57.1% {
    background-image: var(--loader-image);
    color: transparent;
  }
  60% {
    background-image: var(--loader-image);
    color: transparent;
  }
  60.1% {
    background-image: none;
    color: rgba(var(--text-color));
  }
  65% {
    background-image: none;
    color: rgba(var(--text-color));
  }
  65.1% {
    background-image: var(--loader-image);
    color: transparent;
  }
  75% {
    background-image: var(--loader-image);
    color: transparent;
  }
  75.1% {
    background-image: none;
    color: rgba(var(--text-color));
  }
  77% {
    background-image: none;
    color: rgba(var(--text-color));
  }
  77.1% {
    background-image: var(--loader-image);
    color: transparent;
  }
  85% {
    background-image: var(--loader-image);
    color: transparent;
  }
  85.1% {
    background-image: none;
    color: rgba(var(--text-color));
  }
  86% {
    background-image: none;
    color: rgba(var(--text-color));
  }
  86.1% {
    background-image: var(--loader-image);
    color: transparent;
  }
  100% {
    background-image: var(--loader-image);
    color: transparent;
  }
}

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;

  h1 {
    font-size: 18em;
    text-align: center;

    span {
      animation: text-flicker-in-glow 6s linear infinite both;
      background-image: none;
      background: transparent var(--loader-image) center center no-repeat;
      color: rgba(var(--text-color));
      padding: 0 .1em;
      position: relative;
    }

    @media (max-width: 980px) {
      font-size: 13em;
    }

    @media (max-width: 650px) {
      font-size: 9em;
    }

    @media (max-width: 500px) {
      font-size: 6em;
    }
  }

  p {
    margin-top: 20px;
    text-align: center;
  }
}
