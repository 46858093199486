@import '~cms/styles/mixins.less';
@import '~bootstrap-less-port/less/mixins/_buttons.less';

.header {
  align-items: inherit;
}

.create {
  #button-variant(@weplayed-blue-light, @weplayed-blue-light);
}

.controls {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1em;
}

.filter {
  flex: 1;
  margin-right: 1em;
}

.sort {
  .masked-button('~@mdi/svg/svg/sort.svg', 60%);
  text-indent: 0;
  padding-right: 2em;
  padding-left: 1em;
  height: 2em;

  &::after {
    width: 2em;
  }
}

.owner {
  background-color: transparent;
  border: none;
  color: @body-color;
  height: 2em;
}

.collections {
  .scrollbar();

  margin: 1em 0;
  padding: 0.25em 0.5em 0.25em 0;
  height: 50vh;
  overflow-y: auto;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    margin: 0;
    padding: 0.25em;
    user-select: none;
    display: flex;

    &:hover {
      background-color: fade(@body-color, 10%);
    }
  }
}

.many > :global(.custom-control-input):checked ~ :global(.custom-control-label)::before {
  background-color: @secondary;
  border-color: @secondary;
}

.label {
  flex: 1;
  margin-left: 0.25em;
}
