@import '~common/styles/variables.less';

@background: transparent;
@border-radius: 0.25em;
@buttons-width: 3em;
@control-width: 3em;
@knob-width: 1em;
@effectsDuration: .3s;

.root {
  border: solid (@border-radius * 2) @background;
  color: @body-color;
  display: flex;
  flex-direction: row;
  font-size: 10px;
  font-weight: bold;
  line-height: 10px;
  margin-bottom: 2em;
  user-select: none;
  width: 100%;

  &.disabled {
    pointer-events: none;
    touch-action: none;
    filter: grayscale();
    opacity: 0.2;
  }

  button {
    color: @body-color;
    border: none;
    padding: 0;
  }

  > button {
    background-color: @background;
    box-sizing: content-box;
    width: @buttons-width;

    &:disabled {
      svg {
        opacity: .2;
      }
    }

    &.start {
      padding-right: @control-width;
    }

    &.end {
      padding-left: @control-width;
    }

    svg {
      height: 60%;
      width: 60%;
    }
  }
}

.hover {
  border: solid 0.5px fade(@body-color, 50%);
  display: none;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  touch-action: none;
}

.withPreview {
  .hover {
    display: block;
  }
}

.preview {
  pointer-events: none;
  position: absolute;
  top: (-4 * @border-radius);
  touch-action: none;
  width: 100%;
}

.container {
  flex: 1;
  position: relative;
}

.span {
  display: block;
  margin: (-2*@border-radius) 0;
  position: relative;
}

.inner {
  background-color: darken(@weplayed-accent, 10%);
  border: solid @border-radius  @weplayed-accent;
  border-left-width: @control-width;
  border-radius: @border-radius;
  border-right-width: @control-width;
  display: flex;
  flex-direction: column;
  margin: 0 -@control-width;
  position: relative;
}

.ruler {
  background: url('./ruler.svg') transparent repeat-x left bottom;
  // width will be set
  background-size: 100px 4px;
  position: absolute;
  top: calc(100% + (@border-radius * 2));
  height: 2em;
  width: 100%;
}

.controls {
  bottom: @border-radius;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: @border-radius;
  width: @control-width;

  button {
    align-items: center;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: (@control-width - @knob-width);

    span {
      font-size: 0.8em;
      line-height: 0.6em;
    }

    svg {
      height: 100%;
      width: 100%;
    }
  }

  &:first-child {
    left: -@control-width;
    padding-right: 0.5em;
  }

  &:last-child {
    padding-left: 0.5em;
    right: -@control-width;
  }
}

.handle {
  -webkit-mask-image: url(./drag.svg);
  -webkit-mask-position: center center;
  -webkit-mask-repeat: repeat-y;
  -webkit-mask-size: 35%;
  background-color: fade(@body-color, 50%);
  cursor: ew-resize;
  height: 100%;
  mask-image: url(./drag.svg);
  mask-position: center center;
  mask-repeat: repeat-y;
  mask-size: 35%;
  width: @knob-width;
}

.position {
  border: solid 1px @body-color;
  bottom: 0;
  position: absolute;
  top: 0;
  width: 0;
}

.time {
  align-items: center;
  display: flex;
  height: 3em;
  justify-content: center;
}

.times {
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  position: absolute;
  top: calc(100% + (2 * @border-radius));
  touch-action: none;
  width: 100%;
}

.children {
  flex: 1;
}

.withAnimations {
  .span {
    transition: left @effectsDuration, width @effectsDuration;
  }

  .ruler {
    transition: background-size @effectsDuration;
  }
}
