@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';
@import '../mixins.less';

.checkbox {
  width: 3em;
}

.control {
  max-width: 3em;
  min-width: 3em;
  width: 3em;
}

.teams {
  min-width: 7em;
}

.thumbnail {
  transition: width ease-in @animation;
  user-select: none;
  /* will be expanded automatically */
  width: 5em;
}

.position {
  width: 3em;
  max-width: 3em;
}

.name {
  width: 100%;
}

.sport {
  min-width: 12em;
  white-space: nowrap;
}

.athlete {
  min-width: 15em;
}

.date {
  max-width: 10em;
  min-width: 10em;
  width: 10em;
}

.game {
  min-width: 15em;
}

.creator {
  min-width: 8em;
}

.score {
  min-width: 6em;
}

.moments {
  min-width: 7em;
}
