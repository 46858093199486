@import '~common/styles/variables.less';

@previewWidth: 120px;
@spinnerSize: @previewWidth * 0.2;
@spinnerThickness: 2px;

:export {
  preview-width: @previewWidth;
}

.video {
  align-items: center;
  background-color: @weplayed-background;
  border: solid 1px @weplayed-accent;
  bottom: 100%;
  display: none;
  justify-content: center;
  position: absolute;
  width: @previewWidth;

  svg {
    fill: @weplayed-foreground;
    height: @spinnerSize;
    left: 50%;
    margin-left: -@spinnerSize / 2;
    margin-top: -@spinnerSize / 2;
    position: absolute;
    top: 50%;
    width: @spinnerSize;
  }

  :global(.vjs-loading-spinner) {
    border-radius: (@spinnerSize / 2);
    border-width: @spinnerThickness;
    height: @spinnerSize;
    margin: -(@spinnerSize / 2) 0 0 -(@spinnerSize / 2);
    width: @spinnerSize;

    &::before, &::after {
      margin: -@spinnerThickness;
    }
  }
}


.root {
  position: relative;

  &.visible .video {
    display: block;
  }
}
