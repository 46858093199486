.root {
  display: inline-block;
  padding: 0 !important;
  position: relative;

  div {
    background-color: rgba(12, 15, 17, 0.3);
    bottom: 100%;
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    width: 100%;

    div {
      align-items: center;
      display: flex;
      height: 12em;
      justify-content: center;
      width: 100%;

      input {
        transform: rotate(270deg);
        width: 10em;
      }
    }
  }
}

.button {
  background: transparent;
  border: none;

  svg {
    height: 2em;
    width: 2em;
  }
}
