.root {
  display: block;
  font-size: 0.8em;
  padding: 0.5em;
  text-align: center;
  color: #777;

  a {
    color: #777;
    display: inline-block;
    margin: 0 1em;
  }
}
