@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';

.root {
  position: fixed;
  bottom: 2em;
  left: 50%;
  transition: bottom @animation-duration;
  z-index: 5;

  &.hidden {
    bottom: -4em;
  }
}

.content {
  background-color: @body-bg;
  border-radius: 0.333em;
  box-shadow: 0 0 0.333em @weplayed-foreground;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1em 0.5em;

  > * {
    margin: 0 0.5em;
  }
}

.button {
  .masked-button('~@mdi/svg/svg/gesture-tap.svg', 80%);

  height: calc((@input-btn-padding-y * 2) + 1em);
  width: calc((@input-btn-padding-y * 2) + 1em);
}
