@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';

.like {
  &::after {
    .button-mask-url('~images/star-partial.svg');
  }

  &.none::after {
    .button-mask-url('~@mdi/svg/svg/star-outline.svg');
  }

  &.all::after {
    .button-mask-url('~@mdi/svg/svg/star.svg');
  }
}

.download {
  &::after {
    .button-mask-url('~@mdi/svg/svg/download.svg');
  }

  &.loading::after {
    .button-mask-url('~images/spinner.svg');
  }
}

.remove {
  &::after {
    .button-mask-url('~@mdi/svg/svg/delete.svg');
    background-color: @danger;
  }

  &.loading::after {
    .button-mask-url('~images/spinner.svg');
  }
}

.collection::after {
  .button-mask-url('~@mdi/svg/svg/playlist-plus.svg');
}
