@import '~cms/styles/variables.less';

.input {
  width: 10em;
}

.iframe {
  height: 0;
  overflow: hidden;
  width: 100%;
}

.textarea {
  margin-top: 1em;
}
