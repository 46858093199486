@import '~cms/styles/variables.less';
@import '~cms/styles/mixins.less';
@import '../../mixins.less';

.control {
  display: block;
  height: 2em;
  width: 2em;

  &:disabled {
    filter: grayscale();
    opacity: 0.2;
  }
}

.hidden {
  opacity: 0;
  pointer-events: none;
  touch-action: none;
}

.more {
  .masked-button('~@mdi/svg/svg/chevron-right.svg', 100%);

  &::after {
    transition: transform @animation;
  }

  &.open::after {
    transform: rotateZ(90deg);
  }
}

.link {
  .masked-button('~@mdi/svg/svg/link.svg', 60%);
}

.like svg {
  width: 60%;
  height: 60%;
}

.menu {
  .masked-button('~@mdi/svg/svg/dots-horizontal.svg', 60%);
}

.cancel {
  .masked-button('~@mdi/svg/svg/close.svg', 60%);

  &::after {
    background-color: red;
  }
}

.pin {
  .masked-button('~@mdi/svg/svg/pin-outline.svg', 60%);

  &::after {
    transform: rotateZ(30deg);
    transition: transform @animation;
  }

  &.pinned {
    &::after {
      .button-mask-url('~@mdi/svg/svg/pin.svg');

      background-color: @weplayed-accent;
      transform: inherit;
    }

    &.temporary::after {
      background-color: gray;
    }
  }

  &.loading::after {
    .button-mask-url('~images/spinner.svg');
  }
}

.block {
  margin-right: 0.75em !important;
  font-size: 0.75em;
}

.avatar {
  height: 1.5em;
  margin-left: 0.5em;
  vertical-align: -0.2rem;
  width: 1.5em;
}

.avatarName {
  font-size: 0.7em;
  margin-left: 0.5em;
  vertical-align: 1px;
}

.visibility {
  .masked-button('~@mdi/svg/svg/lock-outline.svg', 60%);

  opacity: 0;
  pointer-events: none;
  touch-action: none;

  &.priv {
    opacity: 1;
  }
}

[data-size="compact"] {
  .avatarName {
    display: none;
  }
}
